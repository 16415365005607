<template>
  <v-row class="match-height">
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-form
        ref="form"
        class="multi-col-validation"
        lazy-validation
      >
        <v-card>
          <v-card-title>{{ formTitulo }}</v-card-title>
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              :disabled="tab.disabled"
              @click="keyComponnet += 1"
            >
              <v-icon
                v-if="tab.error"
                color="error"
              >
                {{ tab.icons }}
              </v-icon>
              <span class="mx-2">{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <!-- Imagen -->
                <v-col
                  cols="12"
                  md="4"
                  class="d-flex justify-center align-center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      max-width="300"
                      max-height="300"
                      height="300"
                      width="300"
                      class="mx-auto"
                    >
                      <v-container class="pa-1">
                        <v-img
                          v-if="isUrl(obj.imageUrl)"
                          :src="obj.imageUrl"
                          height="300"
                          class="text-right"
                          @error="offLink = true"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0 grey"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                v-if="offLink === false"
                                size="70"
                                indeterminate
                                color="grey lighten-5"
                              >
                                <!-- <v-icon size="50" dark>{{
                                                icono_imagen
                                              }}</v-icon>-->
                              </v-progress-circular>
                              <v-icon
                                v-else
                                size="50"
                                dark
                              >
                                {{
                                  icono_link
                                }}
                              </v-icon>
                            </v-row>
                          </template>

                          <v-expand-transition>
                            <div
                              v-if="hover && isEditing"
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                primary
                                darken-2
                                v-card--reveal
                                white--text
                              "
                              style="height: 100%"
                              @click="onPickFile"
                            >
                              <div>
                                <div
                                  class="
                                    d-flex
                                    justify-center
                                    white--text
                                    display-1
                                  "
                                >
                                  Click
                                </div>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-img>
                        <v-img
                          v-else
                          :src="imageUrlDefault"
                          max-width="300"
                          max-height="300"
                          height="300"
                          width="300"
                          class="text-right"
                          @error="offLink = true"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0 grey"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                v-if="offLink === false"
                                size="70"
                                indeterminate
                                color="grey lighten-5"
                              >
                                <!-- <v-icon size="50" dark>{{
                                                icono_imagen
                                              }}</v-icon>-->
                              </v-progress-circular>
                              <v-icon
                                v-else
                                size="50"
                                dark
                              >
                                {{
                                  icono_link
                                }}
                              </v-icon>
                            </v-row>
                          </template>

                          <v-expand-transition>
                            <div
                              v-if="hover && isEditing"
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                primary
                                darken-2
                                v-card--reveal
                                white--text
                              "
                              style="height: 100%"
                              @click="onPickFile"
                            >
                              <div>
                                <div
                                  class="
                                    d-flex
                                    justify-center
                                    white--text
                                    display-1
                                  "
                                >
                                  Click
                                </div>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-img>
                        <input
                          id="fileInput"
                          ref="refFileInput"
                          type="file"
                          accept="image/*"
                          style="display: none"
                          :disabled="!isEditing"
                          :rules="[
                            (v) => !!v || 'El campo Foto es obligatorio',
                          ]"
                          @change="onFilePicked"
                        />
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-row>
                    <!-- Nombre -->
                    <v-col cols="12">
                      <v-text-field
                        ref="refName"
                        v-model="obj.name"
                        label="Nombre"
                        outlined
                        dense
                        placeholder="Nombre"
                        error-count="2"
                        :error="campos.name.error"
                        :error-messages="errorMsgName"
                        counter="100"
                        maxlength="100"
                        :rules="[
                          (v) => !!v || 'El campo Nombre es obligatorio',
                          (v) => v.length <= 100 || 'Este campo solo puede tener 100 caracteres',
                        ]"
                      >
                        <template #label>
                          <app-label
                            field="Nombre"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- Descripcion -->
                    <v-col cols="12">
                      <v-textarea
                        ref="refDescripcion"
                        v-model="obj.description"
                        outlined
                        dense
                        height="230"
                        placeholder="Acerca de / Descripción"
                        error-count="2"
                        :error="campos.description.error"
                        :error-messages="errorMsgDescription"
                        counter="500"
                        maxlength="500"
                        :rules="[
                          (v) => !!v || 'El campo Descripción es obligatorio',
                          (v) => v.length <= 500 || 'Este campo solo puede tener 500 caracteres'
                        ]"
                      >
                        <template #label>
                          <app-label
                            field="Descripción"
                            required="true"
                          />
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                </v-col>

                <!-- Comentario -->
                <v-col
                  v-if="false"
                  cols="12"
                >
                  <v-text-field
                    v-model="obj.comment"
                    label="Comentario"
                    outlined
                    dense
                    placeholder="Comentario"
                    error-count="2"
                    :error="campos.comment.error"
                    :error-messages="errorMsgComment"
                    :rules="[
                      (v) => !!v || 'El campo Comentario es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Comentario"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Modalidad -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    ref="refModalidad"
                    v-model="obj.modality"
                    :items="modalidades"
                    item-value="id"
                    item-text="name"
                    label="Modalidad"
                    dense
                    outlined
                    error-count="2"
                    :error="campos.modality.error"
                    :error-messages="errorMsgModality"
                    :rules="[
                      (v) => !!v || 'El campo Modalidad es obligatorio',
                    ]"
                    @change="disabledTab"
                  >
                    <template #label>
                      <app-label
                        field="Modalidad"
                        required="true"
                      />
                    </template>
                  </v-select>
                </v-col>

                <!-- URL de la Sesión Virtual / Mixta -->
                <!-- :disabled="disableByModality()"
                    :rules="[
                      (v) => (!v || requiredByModality(v) ) || 'El campo URL de la Sesión es obligatorio',
                      (v) => (obj.modality === 'FaceToFace' || isUrlValid(v)) || 'Formato URL es incorrecto',
                    ]" -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <!-- :disabled="disableByModality()" -->
                  <v-text-field
                    ref="refLinkSessionUrlModality"
                    v-model="obj.linkSessionUrlModality"
                    outlined
                    dense
                    label="URL de la Sesión Virtual / Mixta"
                    placeholder="URL de la Sesión Virtual / Mixta"
                    :rules="[
                      (v) => (requiredByModality(v) ) || 'El campo URL de la Sesión es obligatorio',
                      (v) => (obj.modality === 'FaceToFace' || isUrlValid(v)) || 'Formato URL es incorrecto',
                    ]"
                    error-count="2"
                  >
                    <template
                      v-if="obj.modality === 'Mixed' || obj.modality === 'Virtual'"
                      #label
                    >
                      <app-label
                        field="URL de la Sesión Virtual / Mixta"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Nombre de la URL de la Sesión -->
                <!-- :disabled="disableByModality()" -->
                <!-- :rules="[
                      (v) => (!v || requiredByModality(v) ) || 'El campo Nombre de la URL es obligatorio',
                      (v) => (obj.modality === 'FaceToFace' || isValidNameSession(v)) || 'Este campo solo puede tener menos de 200 caracteres',
                    ]" -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <!-- :disabled="disableByModality()" -->
                  <v-text-field
                    ref="refNameSession"
                    v-model="obj.nameSession"
                    label="Nombre de la URL de la Sesión"
                    outlined
                    dense
                    placeholder="Nombre de la URL de la Sesión"
                    counter="30"
                    maxlength="30"
                    :rules="[
                      (v) => requiredByModality(v) || 'El campo Nombre de la URL es obligatorio',
                      (v) => (obj.modality === 'FaceToFace' || isValidNameSession(v)) || 'Este campo solo puede tener menos de 200 caracteres',
                    ]"
                    error-count="2"
                    :error="campos.nameSession.error"
                    :error-messages="errorMsgNameSession"
                  >
                    <template
                      v-if="obj.modality === 'Mixed' || obj.modality === 'Virtual'"
                      #label
                    >
                      <app-label
                        field="Nombre de la URL de la Sesión"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pa-3 mt-2">
                <!-- Requisitos -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-textarea
                    v-model="obj.requirements"
                    label="Requisitos"
                    outlined
                    dense
                    placeholder="Requisitos"
                    counter="250"
                    maxlength="250"
                    :rules="[
                      (v) => isValidLength(v,250) || 'Este campo solo puede tener menos de 250 caracteres',
                    ]"
                  ></v-textarea>
                </v-col>

                <!-- Fecha del creación -->
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="refEventDate"
                        v-model="obj.eventDate"
                        placeholder="Fecha del evento"
                        persistent-hint
                        :prepend-inner-icon="icons.mdiCalendar"
                        outlined
                        dense
                        readonly
                        :disabled="false"
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'El campo Fecha del evento es obligatorio',
                        ]"
                        :error="campos.eventDate.error"
                        :error-messages="errorMsgEventDate"
                        v-on="on"
                      >
                        <template #label>
                          <app-label
                            field="Fecha de evento"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="obj.eventDate"
                      no-title
                      :min="minDate"
                      @input="menu2 = false"
                      @change="changeDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Hora del evento -->
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="obj.eventHour"
                    type="time"
                    suffix="Hora"
                    label="Hora"
                    outlined
                    dense
                    placeholder="Hora del evento"
                    :rules="[
                      (v) => !!v || 'El campo Hora del evento es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Hora del evento"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Fecha de publicacion -->
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="refPublishAt"
                        v-model="obj.publishAt"
                        label="Fecha de publicación"
                        placeholder="Fecha de publicación"
                        persistent-hint
                        :prepend-inner-icon="icons.mdiCalendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'El campo Fecha de publicación es obligatorio',
                        ]"
                        v-on="on"
                      >
                        <template #label>
                          <app-label
                            field="Fecha de publicación"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="obj.publishAt"
                      no-title
                      :min="minDatePublishAt"
                      :max="obj.eventDate"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Fecha de caducidad -->
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="refExpireAt"
                        v-model="obj.expireAt"
                        persistent-hint
                        :prepend-inner-icon="icons.mdiCalendar"
                        placeholder="Fecha de caducidad"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'El campo Fecha de caducidad es obligatorio',
                        ]"
                        v-on="on"
                      >
                        <template #label>
                          <app-label
                            field="Fecha de expiración"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="obj.expireAt"
                      no-title
                      :min="minDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Categoria -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    ref="refCategoria"
                    v-model="obj.eventCategory"
                    :items="listaCategorias"
                    :loading="loadingCategorias"
                    item-value="id"
                    item-text="name"
                    label="Categorías"
                    dense
                    outlined
                    return-object
                    :rules="[
                      (v) => !!v || 'El campo Categorías es obligatorio',
                    ]"
                    @change="getSubCategorys(obj.eventCategory)"
                  >
                    <template #label>
                      <app-label
                        field="Categorías"
                        required="true"
                      />
                    </template>
                  </v-select>
                </v-col>

                <!-- SubCategoria -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    ref="refSubCategoria"
                    v-model="obj.eventSubCategory"
                    :items="listaSubCategorias"
                    :loading="loadingSubCategorias"
                    item-value="subCategoryId"
                    item-text="subCategoryName"
                    label="Sub Categorías"
                    dense
                    outlined
                    return-object
                    :rules="[
                      (v) => !!v || 'El campo Sub Categorías es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Sub Categorías"
                        required="true"
                      />
                    </template>
                  </v-select>
                </v-col>

                <!-- Area -->
                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    ref="refArea"
                    v-model="obj.area"
                    :items="listaAreas"
                    :loading="loadingAreas"
                    item-value="id"
                    item-text="name"
                    label="Área"
                    dense
                    outlined
                    return-object
                    :rules="[
                      (v) => !!v || 'El campo Área es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Área"
                        required="true"
                      />
                    </template>
                  </v-select>
                </v-col> -->
              </v-row>
            </v-tab-item>
            <!-- Mapa -->
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <!-- Nombre de la Sede -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refNameLocation"
                    v-model="obj.nameLocation"
                    label="Nombre de la Sede"
                    outlined
                    dense
                    placeholder="Nombre de la Sede"
                    :rules="[
                      (v) => requiredLocationsByModality(v) || 'El campo Nombre de la Sede del evento es obligatorio',
                    ]"
                    :error="campos.nameLocation.error"
                    :error-messages="errorMsgNameLocation"
                  >
                    <template
                      v-if="obj.modality === 'Mixed' || obj.modality === 'FaceToFace'"
                      #label
                    >
                      <app-label
                        field="Nombre de la Sede"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <!-- Longitud -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refLongitud"
                    v-model="obj.longitude = obj.location[0]"
                    label="Longitud"
                    outlined
                    dense
                    readonly
                    placeholder="Longitud"
                    error-count="2"
                    :rules="[
                      (v) => requiredLocationsByModality(v) || 'El campo Longitud es obligatorio',
                    ]"
                  >
                    <template
                      v-if="obj.modality === 'Mixed' || obj.modality === 'FaceToFace'"
                      #label
                    >
                      <app-label
                        field="Longitud"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Latitud -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refLatitud"
                    v-model="obj.latitude = obj.location[1]"
                    label="Latitud"
                    outlined
                    dense
                    readonly
                    placeholder="Latitud"
                    error-count="2"
                    :rules="[
                      (v) => requiredLocationsByModality(v) || 'El campo Latitud es obligatorio',
                    ]"
                  >
                    <template
                      v-if="obj.modality === 'Mixed' || obj.modality === 'FaceToFace'"
                      #label
                    >
                      <app-label
                        field="Latitud"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Mapa -->
                <v-col
                  cols="12"
                >
                  <LocationPicker
                    :key="keyComponnet"
                    ref="locationPicker"
                    v-model="obj.location"
                    :api-key="apiKey"
                    :geo="geo"
                    :search-form="searchForm"
                    :color="color"
                    :initial-location="ubicacionInicial"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions
            v-if="true"
            class="mt-5"
          >
            <v-row>
              <!-- Botones de acción -->
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="loadingSaveForm"
                  type="button"
                  @click.prevent="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="notificaciones.snackbar"
          :bottom="notificaciones.y === 'bottom'"
          :color="notificaciones.color"
          :left="notificaciones.x === 'left'"
          :multi-line="notificaciones.mode === 'multi-line'"
          :right="notificaciones.x === 'right'"
          :timeout="notificaciones.timeout"
          :top="notificaciones.y === 'top'"
          :vertical="notificaciones.mode === 'vertical'"
        >
          {{ notificaciones.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              text
              v-bind="attrs"
              @click="notificaciones.snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar, mdiAlertOctagonOutline,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@/utils'
import LocationPicker from '@/components/LocationPicker.vue'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: {
    Breadcrumbs,
    LocationPicker,
    AppLabel,
  },
  setup() {
    const apiKey = 'pk.eyJ1Ijoiam9zZTg5MDgyMyIsImEiOiJja3plZjIzczMwZjEwMm9vYmdpam81b2Y4In0.4BCud0O62PlRyUwXYVaXCQ'
    const tab = ref(0)
    const activeTabsError = ref(false)
    const keyComponnet = ref(0)

    // tabs
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false, disabled: false,
      },
      {
        id: '2', title: 'Ubicación', icons: mdiAlertOctagonOutline, error: false, disabled: false,
      },

      // { id: '2', title: 'Requisitos / Tiempo' },
    ])
    const status = [
      { id: 'approved', name: 'Aprobado' },
      { id: 'pending', name: 'Pendiente' },
      { id: 'denied', name: 'Denegado' },
      { id: 'canceled', name: 'Cancelado' },
    ]
    const modalidades = [
      { id: 'Mixed', name: 'Presencial y Virtual' },
      { id: 'FaceToFace', name: 'Presencial' },
      { id: 'Virtual', name: 'Virtual' },
    ]
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const { router } = useRouter()
    const formTitulo = ref('Crear nuevo evento')
    const listaAreas = reactive([])
    const listaCategorias = reactive([])
    const listaSubCategorias = reactive([])
    const keyeventSubCategory = ref(0)
    const offLink = ref(false)
    const isEditing = ref(true)
    const imageUrlDefault = ref(require('@/assets/images/default-img.png'))
    const icono_link = ref(require('@/assets/images/default-img.png'))
    const refFileInput = ref(null)
    const form = ref(null)
    const refNameLocation = ref(null)
    const refName = ref(null)
    const refDescripcion = ref(null)
    const refModalidad = ref(null)
    const refLinkSessionUrlModality = ref(null)
    const refNameSession = ref(null)
    const refArea = ref(null)
    const refCategoria = ref(null)
    const refSubCategoria = ref(null)
    const refProgramDate = ref(null)
    const refPublishAt = ref(null)
    const refExpireAt = ref(null)
    const refLatitud = ref(null)
    const refLongitud = ref(null)
    const formDisabled = ref(false)
    const minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    const obj = reactive({
      name: '',
      description: '',
      comment: '',
      modality: null,
      linkSessionUrlModality: '',
      nameSession: '',
      requirements: '',
      eventDate: '',
      eventHour: '00:00',
      eventMinute: '',
      publishAt: '',
      expireAt: '',
      imageUrl: '',
      nameLocation: '',
      latitude: '',
      longitude: '',
      scheduleDuration: '',
      eventCategoryId: '',
      eventCategory: '',
      eventSubCategoryId: '',
      eventSubCategory: '',
      areaId: '',
      area: '',
      location: [],
    })
    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      description: {
        error: false,
        mensajesError: [],
      },
      comment: {
        error: false,
        mensajesError: [],
      },
      modality: {
        error: false,
        mensajesError: [],
      },
      requirements: {
        error: false,
        mensajesError: [],
      },
      eventDate: {
        error: false,
        mensajesError: [],
      },
      eventHour: {
        error: false,
        mensajesError: [],
      },
      publishAt: {
        error: false,
        mensajesError: [],
      },
      expireAt: {
        error: false,
        mensajesError: [],
      },
      imageUrl: {
        error: false,
        mensajesError: [],
      },
      nameLocation: {
        error: false,
        mensajesError: [],
      },
      latitude: {
        error: false,
        mensajesError: [],
      },
      longitude: {
        error: false,
        mensajesError: [],
      },
      eventCategoryId: {
        error: false,
        mensajesError: [],
      },
      eventCategory: {
        error: false,
        mensajesError: [],
      },
      eventSubCategoryId: {
        error: false,
        mensajesError: [],
      },
      eventSubCategory: {
        error: false,
        mensajesError: [],
      },
      areaId: {
        error: false,
        mensajesError: [],
      },
      area: {
        error: false,
        mensajesError: [],
      },
      nameSession: {
        error: false,
        mensajesError: [],
      },
    })
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Eventos',
        disabled: false,
        href: '/eventos',
      },
      {
        text: 'Crear evento',
        disabled: true,
        href: '/eventos/crear-eventos',
      },
    ])
    const loadingSaveForm = ref(false)
    const loadingAreas = ref(false)
    const loadingCategorias = ref(false)
    const loadingSubCategorias = ref(false)
    const geo = ref(true)
    const searchForm = ref(true)
    const height = ref(500)
    const color = ref('#FFA500')
    const ubicacionInicial = ref([-79.88245227233337, -2.202246732389483])

    // Methods
    const getCategorys = async () => {
      loadingCategorias.value = true
      const URL = `${HTTP.defaults.baseURL}/events-api/event-categories?page=1&pageSize=1000000`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          listaCategorias.push(resp.data.value.entities[index])
        }

        // Ordenar alfabeticamente
        listaCategorias.sort((a, b) => {
          if (a.name.toLowerCase() === b.name.toLowerCase()) {
            return 0
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }

          return 1
        })

        loadingCategorias.value = false
      }
    }

    const getSubCategorys = async objCategoria => {
      if (objCategoria) {
        listaSubCategorias.length = 0 // Limpia el array
        for (let index = 0; index < objCategoria.subCategories.length; index += 1) {
          listaSubCategorias.push(objCategoria.subCategories[index])
        }

        // Ordenar alfabeticamente
        listaSubCategorias.sort((a, b) => {
          if (a.subCategoryName.toLowerCase() === b.subCategoryName.toLowerCase()) {
            return 0
          }
          if (a.subCategoryName.toLowerCase() < b.subCategoryName.toLowerCase()) {
            return -1
          }

          return 1
        })
      }
    }

    const getAreas = async () => {
      loadingAreas.value = true
      const URL = `${HTTP.defaults.baseURL}identity-api/areas`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          listaAreas.push(resp.data.value.entities[index])
        }
        loadingAreas.value = false
      }
    }

    const minDatePublishAt = computed(() => moment((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)).toISOString())
    const minDateExpireAt = computed(() => moment(obj.eventDate).toISOString())

    // Errores
    const errorMsgName = computed(() => (campos.name.error ? campos.name.mensajesError : []))
    const errorMsgDescription = computed(() => (campos.description.error ? campos.description.mensajesError : []))
    const errorMsgComment = computed(() => (campos.comment.error ? campos.comment.mensajesError : []))
    const errorMsgModality = computed(() => (campos.modality.error ? campos.modality.mensajesError : []))
    const errorMsgRequirements = computed(() => (campos.requirements.error ? campos.requirements.mensajesError : []))
    const errorMsgEventDate = computed(() => (campos.eventDate.error ? campos.eventDate.mensajesError : []))
    const errorMsgEventHour = computed(() => (campos.eventHour.error ? campos.eventHour.mensajesError : []))
    const errorMsgPublishAt = computed(() => (campos.publishAt.error ? campos.publishAt.mensajesError : []))
    const errorMsgExpireAt = computed(() => (campos.expireAt.error ? campos.expireAt.mensajesError : []))
    const errorMsgImageUrl = computed(() => (campos.imageUrl.error ? campos.imageUrl.mensajesError : []))
    const errorMsgNameLocation = computed(() => (campos.nameLocation.error ? campos.nameLocation.mensajesError : []))
    const errorMsgLatitude = computed(() => (campos.latitude.error ? campos.latitude.mensajesError : []))
    const errorMsgLongitude = computed(() => (campos.longitude.error ? campos.longitude.mensajesError : []))
    const errorMsgEventCategoryId = computed(() => (campos.eventCategoryId.error ? campos.eventCategoryId.mensajesError : []))
    const errorMsgEventCategory = computed(() => (campos.eventCategory.error ? campos.eventCategory.mensajesError : []))
    const errorMsgEventSubCategoryId = computed(() => (campos.eventSubCategoryId.error ? campos.eventSubCategoryId.mensajesError : []))
    const errorMsgEventSubCategory = computed(() => (campos.eventSubCategory.error ? campos.eventSubCategory.mensajesError : []))
    const errorMsgAreaId = computed(() => (campos.areaId.error ? campos.areaId.mensajesError : []))
    const errorMsgArea = computed(() => (campos.area.error ? campos.area.mensajesError : []))
    const errorMsgNameSession = computed(() => (campos.nameSession.error ? campos.nameSession.mensajesError : []))

    const resetField = () => {
      refNameSession.value.reset()
      refLinkSessionUrlModality.value.reset()
    }

    const disableByModality = () => {
      if (obj.modality === 'FaceToFace' || !obj.modality) {
        return true
      }

      return false
    }

    const disableLocationsByModality = () => {
      if (obj.modality === 'Virtual' || !obj.modality) {
        tabs[1].disabled = true

        return true
      }
      tabs[1].disabled = false

      return false
    }

    const resetLocation = () => {
      tabs[1].error = false
      refNameLocation.value.reset()
      refLatitud.value.reset()
      refLongitud.value.reset()
    }

    const disabledTab = v => {
      if (v === 'Virtual') {
        tabs[1].disabled = true
        refNameLocation.value.reset()
        refLatitud.value.reset()
        refLongitud.value.reset()
      } else {
        tabs[1].disabled = false
      }
    }

    const requiredByModality = v => {
      let temp = true
      if (v) {
        temp = true
      } else if (obj.modality === 'Mixed' || obj.modality === 'Virtual') {
        temp = false
      }

      return temp
    }

    const requiredLocationsByModality = v => {
      let temp = true
      if (v) {
        temp = true
      } else if (obj.modality === 'Mixed' || obj.modality === 'FaceToFace') {
        temp = false
      }

      return temp
    }

    const isUrl = s => {
      if (s === '') return false
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }

    const isUrlValid = s => {
      if (s === '') return true
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }

    const isValidName = s => {
      if (s === '') return true
      const regexp = /^[A-Za-zÀ-ÿ]+([\\ ]{0,1}[A-Za-z0-9À-ÿ]+)*$/

      return regexp.test(s)
    }

    // Llamada de los Metodos
    getCategorys()
    getAreas()

    return {
      menu: ref(false),
      menu2: ref(false),
      menu3: ref(false),
      apiKey,
      tab,
      tabs,
      activeTabsError,
      form,
      refNameLocation,
      refName,
      refDescripcion,
      refModalidad,
      refLinkSessionUrlModality,
      refNameSession,
      refArea,
      refCategoria,
      refSubCategoria,
      refProgramDate,
      refPublishAt,
      refExpireAt,
      formDisabled,
      items,
      formTitulo,
      status,
      offLink,
      isEditing,
      refFileInput,
      imageUrlDefault,
      icono_link,
      modalidades,
      listaAreas,
      listaCategorias,
      listaSubCategorias,
      keyeventSubCategory,
      loadingAreas,
      loadingCategorias,
      loadingSubCategorias,
      loadingSaveForm,
      obj,
      campos,
      geo,
      searchForm,
      height,
      color,
      ubicacionInicial,
      notificaciones,
      keyComponnet,
      minDate,
      minDatePublishAt,
      minDateExpireAt,
      isValidName,
      refLatitud,
      refLongitud,
      disabledTab,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
      },
      getSubCategorys,
      isUrl,
      isUrlValid,

      // Errores
      errorMsgName,
      errorMsgDescription,
      errorMsgComment,
      errorMsgModality,
      errorMsgRequirements,
      errorMsgEventDate,
      errorMsgEventHour,
      errorMsgPublishAt,
      errorMsgExpireAt,
      errorMsgImageUrl,
      errorMsgNameLocation,
      errorMsgLatitude,
      errorMsgLongitude,
      errorMsgEventCategoryId,
      errorMsgEventCategory,
      errorMsgEventSubCategoryId,
      errorMsgEventSubCategory,
      errorMsgNameSession,
      errorMsgAreaId,
      errorMsgArea,
      disableByModality,
      disableLocationsByModality,
      requiredByModality,
      requiredLocationsByModality,
      resetField,
      resetLocation,

      esHoraValida: v => (v.split(':')[0] >= 0 && v.split(':')[0] < 24) || 'El campo hora debe estar entre 0 y 23',
      changeDate: () => {
        refPublishAt.value.reset()
        refExpireAt.value.reset()
      },
      onPickFile: () => {
        // Cuando se hace click en el boton, activa el file input
        refFileInput.value.click()
      },
      onFilePicked: event => {
        const { files } = event.target
        const filename = files[0] ? files[0].name : ''
        const size = files[0] ? files[0].size : ''
        const ext = files[0] ? files[0].type : ''
        if (files[0]) {
          // si no tiene una extension muestro un error
          if (filename && filename.lastIndexOf('.') <= 0) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Por favor selecciona un archivo valido'
          } else if (size && size >= 1000000) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Máximo 1 fichero. Límite de 1 MB'
          } else if (ext && ext !== 'image/jpeg' && ext !== 'image/jpg' && ext !== 'image/png') {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = `Los archivos de tipo ${ext} no estan permitidos. Tipos permitidos: png jpg jpeg.`
          } else {
            const fileReader = new FileReader()
            if (fileReader) {
              fileReader.addEventListener('load', () => {
                imageUrlDefault.value = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              obj.imageUrl = files[0]
            }
          }
        }
      },
      cancelarForm: () => {
        router.push({ name: 'eventos' })
      },

      saveForm: async () => {
        if (obj.imageUrl === '') {
          notificaciones.color = 'error'
          notificaciones.text = 'El campo Imagen es obligatorio'
          notificaciones.snackbar = true
        } else if (form.value.validate()) {
          loadingSaveForm.value = true
          const objToSave = {
          // obj
            name: obj.name,
            description: obj.description,
            comment: obj.comment,
            modality: obj.modality,
            linkSessionUrlModality: obj.linkSessionUrlModality,
            nameSession: obj.nameSession,

            // obj
            requirements: obj.requirements,
            eventDate: obj.eventDate,
            publishAt: obj.publishAt,
            expireAt: obj.expireAt,
            eventHour: obj.eventHour,
            eventMinute: 0,
            scheduleDuration: obj.scheduleDuration,

            eventCategoryId: obj.eventCategory ? obj.eventCategory.id : null,
            eventSubCategoryId: obj.eventSubCategory ? obj.eventSubCategory.subCategoryId : null,

            nameLocation: obj.nameLocation,
            latitude: obj.latitude ? obj.latitude.toString() : '',
            longitude: obj.longitude ? obj.longitude.toString() : '',

          }
          if (objToSave.eventHour.length > 0) {
            const hours = objToSave.eventHour.split(':')[0]
            const minutes = objToSave.eventHour.split(':')[1]
            objToSave.eventHour = parseInt(hours)
            objToSave.eventMinute = parseInt(minutes)
          }
          try {
            const URL = `${HTTP.defaults.baseURL}events-api/events`
            const resp = await HTTP.post(URL, objToSave)
            if (resp.status === 201) {
              loadingSaveForm.value = false
              if (typeof obj.imageUrl === 'object') {
                try {
                  const URLImg = `${HTTP.defaults.baseURL}events-api/events/${resp.data}/images`
                  const formData = new FormData()
                  formData.append('file', obj.imageUrl)
                  const imgResp = await HTTP.post(URLImg, formData)
                } catch (error) {}
              }
              router.push({
                name: 'eventos',
                params: {
                  notificaciones: {
                    snackbar: true,
                    text: 'El registro ha sido creado satisfactoriamente',
                    color: 'success',
                  },
                },
              })
            }
          } catch (error) {
            if (error.response.data.errors.Name) {
              for (let index = 0; index < error.response.data.errors.Name.length; index += 1) {
                campos.name.mensajesError.push(error.response.data.errors.Name[index])
              }
              campos.name.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Description) {
              for (let index = 0; index < error.response.data.errors.Description.length; index += 1) {
                campos.description.mensajesError.push(error.response.data.errors.Description[index])
              }
              campos.description.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Comment) {
              for (let index = 0; index < error.response.data.errors.Comment.length; index += 1) {
                campos.comment.mensajesError.push(error.response.data.errors.Comment[index])
              }
              campos.comment.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Modality) {
              for (let index = 0; index < error.response.data.errors.Modality.length; index += 1) {
                campos.modality.mensajesError.push(error.response.data.errors.Modality[index])
              }
              campos.modality.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.ImageUrl) {
              for (let index = 0; index < error.response.data.errors.ImageUrl.length; index += 1) {
                campos.imageUrl.mensajesError.push(error.response.data.errors.ImageUrl[index])
              }
              campos.imageUrl.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Requirements) {
              for (let index = 0; index < error.response.data.errors.Requirements.length; index += 1) {
                campos.requirements.mensajesError.push(error.response.data.errors.Requirements[index])
              }
              campos.requirements.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventDate) {
              for (let index = 0; index < error.response.data.errors.EventDate.length; index += 1) {
                campos.eventDate.mensajesError.push(error.response.data.errors.EventDate[index])
              }
              campos.eventDate.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventHour) {
              for (let index = 0; index < error.response.data.errors.EventHour.length; index += 1) {
                campos.eventHour.mensajesError.push(error.response.data.errors.EventHour[index])
              }
              campos.eventHour.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventHour) {
              for (let index = 0; index < error.response.data.errors.EventHour.length; index += 1) {
                campos.eventHour.mensajesError.push(error.response.data.errors.EventHour[index])
              }
              campos.eventHour.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.PublishAt) {
              for (let index = 0; index < error.response.data.errors.PublishAt.length; index += 1) {
                campos.publishAt.mensajesError.push(error.response.data.errors.PublishAt[index])
              }
              campos.publishAt.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.ExpireAt) {
              for (let index = 0; index < error.response.data.errors.ExpireAt.length; index += 1) {
                campos.expireAt.mensajesError.push(error.response.data.errors.ExpireAt[index])
              }
              campos.expireAt.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventCategoryId) {
              for (let index = 0; index < error.response.data.errors.EventCategoryId.length; index += 1) {
                campos.eventCategoryId.mensajesError.push(error.response.data.errors.EventCategoryId[index])
              }
              campos.eventCategoryId.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventCategory) {
              for (let index = 0; index < error.response.data.errors.EventCategory.length; index += 1) {
                campos.eventCategory.mensajesError.push(error.response.data.errors.EventCategory[index])
              }
              campos.eventCategory.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventSubCategoryId) {
              for (let index = 0; index < error.response.data.errors.EventSubCategoryId.length; index += 1) {
                campos.eventSubCategoryId.mensajesError.push(error.response.data.errors.EventSubCategoryId[index])
              }
              campos.eventSubCategoryId.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.EventSubCategory) {
              for (let index = 0; index < error.response.data.errors.EventSubCategory.length; index += 1) {
                campos.eventSubCategory.mensajesError.push(error.response.data.errors.EventSubCategory[index])
              }
              campos.eventSubCategory.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.AreaId) {
              for (let index = 0; index < error.response.data.errors.AreaId.length; index += 1) {
                campos.areaId.mensajesError.push(error.response.data.errors.AreaId[index])
              }
              campos.areaId.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Area) {
              for (let index = 0; index < error.response.data.errors.Area.length; index += 1) {
                campos.area.mensajesError.push(error.response.data.errors.Area[index])
              }
              campos.area.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.NameSession) {
              for (let index = 0; index < error.response.data.errors.NameSession.length; index += 1) {
                campos.nameSession.mensajesError.push(error.response.data.errors.NameSession[index])
              }
              campos.nameSession.error = true
              tabs[1].error = true
            }
            if (error.response.data.errors.NameLocation) {
              for (let index = 0; index < error.response.data.errors.NameLocation.length; index += 1) {
                campos.nameLocation.mensajesError.push(error.response.data.errors.NameLocation[index])
              }
              campos.nameLocation.error = true
              tabs[1].error = true
            }
            loadingSaveForm.value = false
          }
        } else {
          // En caso de que alguno de los campos requeridos no son validos, muestro su correspondiente tabs en error
          if (!refName.value.validate() || !refDescripcion.value.validate() || !refModalidad.value.validate()
          || !refCategoria.value.validate() || !refSubCategoria.value.validate()) {
            tabs[0].error = true
            if (tab.value === 1) {
              notificaciones.color = 'error'
              notificaciones.text = 'Complete los campos obligatorios en el Tab de Datos Generales'
              notificaciones.snackbar = true
            }
          } else {
            tabs[0].error = false
          }
          if (!refNameLocation.value.validate() || !refLatitud.value.validate() || !refLongitud.value.validate()) {
            if (tab.value === 0) {
              notificaciones.color = 'error'
              notificaciones.text = 'Complete los campos obligatorios en el Tab de Ubicación'
              notificaciones.snackbar = true
            }
            tabs[1].error = true
          } else {
            tabs[1].error = false
          }
        }
      },

      isValidNameSession: value => {
        // Esta funcion es para validar un campo que no es obligatorio,
        // pero en caso de tener valor que solo admita hasta 200 caracteres
        if (value) {
          if (value.length > 200) {
            return false
          }

          return true
        }

        return true
      },

      isValidLength: (value, number) => {
        // Esta funcion es para validar un campo que no es obligatorio,
        // pero en caso de tener valor que solo admita hasta 200 caracteres
        if (value) {
          if (value.length <= number) {
            return true
          }

          return false
        }

        return true
      },
    }
  },
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
